import logo from "./logo.svg";
import "./App.css";
import { Counter } from "./features/counter/Counter";
import Index from "./web/pages/Index.jsx";
import axios from "axios";
// .     {{{-------------------- Authentication --------------------}}}

import RequireAuth from "./hooks/RequireAuth";
import RequireAuthDashboard from "./hooks/RequireAuthDashboard";
// locale
import { useTranslation } from "react-i18next";

// .     {{{-------------------- Website Pages --------------------}}}
import Shop from "./web/pages/Shop";
import Brands from "./web/pages/Brands";
import FindShop from "./web/pages/FindShop";
import Offers from "./web/pages/Offers";
import FindOffers from "./web/pages/FindOffers";
import Login from "./web/pages/Login";
import ChangePassword from "./web/pages/ChangePassowrd";
import Reset from "./web/pages/Resset";
import Register from "./web/pages/Register";
import Checkout from "./web/pages/Checkout";
import { Route, Routes } from "react-router-dom";
import Cart from "./web/pages/Cart";
import ErrorPage from "./web/componets/ErrorPage";
import Profiles from "./web/pages/Profiles";
import Doctors from "./web/pages/Doctors";
import Information from "./web/pages/Information";
import NewsDetail from "./web/pages/NewsDetail";
import Customer from "./web/pages/Customer.jsx";

// .     {{{-------------------- Daashboard Pages --------------------}}}
import Dashboard from "./Dashboard/pages/Index";
import DashboardOrders from "./Dashboard/pages/Orders/Orders";
import DashboardChat from "./Dashboard/pages/Chat/Chat";
import DashboardLogin from "./Dashboard/pages/Authentication/Login";
import DashboardItems from "./Dashboard/pages/items/Index";
import DashboardCategory from "./Dashboard/pages/Categories/Index";
import DashboardCities from "./Dashboard/pages/Cities/Index";
import DashboardOffers from "./Dashboard/pages/Offers/Index";
import DashboardAskVary from "./Dashboard/pages/AskVary/Index";
import DashboardUsers from "./Dashboard/pages/Users/Index";
import DashboardProfiles from "./Dashboard/pages/Profiles/Index";
import DashboardNews from "./Dashboard/pages/News/Index";
import DashboardConfig from "./Dashboard/pages/Configuration/Index";
import DashboardBrands from "./Dashboard/pages/Brands/Index";
import DashboardSubCategories from "./Dashboard/pages/Subcategories/Index";
import DashboardNotification from "./Dashboard/pages/Notification/Index";
import DashboardClients from "./Dashboard/pages/Clients/Index";
import DashboardReports from "./Dashboard/pages/Reports/Index";
import DashboardReportMostSellingCategories from "./Dashboard/pages/Reports/MostSellingByCategories";
import DahboardContact from "./Dashboard/pages/Contact/Index";
import DahboardCarousel from "./Dashboard/pages/Carousel/Index";
import DahboardBanner from "./Dashboard/pages/Banner/Index";
import DashboardEmail from "./Dashboard/pages/Email/Index";

//      {{{-------------------- Error Boundary--------------------}}}
import ErrorBoundary from './web/pages/ErrorBoundary'

//      {{{-------------------- Rexux Elements--------------------}}}

import { useDispatch, useSelector } from "react-redux";
import { getLocale } from "./features/web/Locale";
import ProductDetails from "./web/pages/ProductDetail";
// import font from "./rudawbold.ttf";
import "./Xendan-Bold.woff";
import AskVary from "./web/pages/AskVary";
import { getConfiguration } from "./features/Global/Configuration";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ScrollToTop from "./ScrolToTop";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// import DashboardProfiles from './Dashboard/pages/Profiles/Item'
import { Sugar } from "react-preloaders";
import Loader from "./Loader";
import { getCarousel } from "./features/Global/Carousel";
import Loading from "./Loading";
import Cookies from "js-cookie";
import Terms from "./web/pages/Terms";
import { getNewArrival } from "./features/Global/NewArrival";
import { useMemo } from "react";
import { getwebBanner } from "./features/Global/Banner";

function App() {
  const [showLoader, setShowLoader] = useState(true);
  const { t } = useTranslation();
  const l =
    Cookies.get("i18nextLng") !== null || Cookies.get("i18nextLng") !== undefined
      ? Cookies.get("i18nextLng")
      : "ar";
  const title = useSelector((state) => state.Configuration.data);
  const titleISSuccess = useSelector((state) => state.Configuration.isSuccess);
  const titleLoading = useSelector((state) => state.Configuration.loading);
  const carousel = useSelector((state) => state.Carousel.data);
  const loading = useSelector((state) => state.Carousel.loading);
  const isSuccess = useSelector((state) => state.Carousel.isSuccess);

  useEffect(() => {
    AOS.init();

    dispatch(getConfiguration());
    dispatch(getCarousel());
    dispatch(getNewArrival());
    dispatch(getwebBanner());


    function handleContextMenu(e) {
      e.preventDefault();
    }
    if (!window.location.href.includes("dashboard")) {
      document.addEventListener("contextmenu", handleContextMenu);
    }

    return () => {
      if (!window.location.href.includes("dashboard")) {
        document.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, []);

  useEffect(() => {
    if (titleISSuccess && !titleLoading) {
      var link = document.querySelector("link[rel~='icon']");
      link.href = title[0]?.imageUrl;

      if (l === "en-US") {
        document.title = title[0]?.englishName;
      }
      if (l === "ar") {
        document.title = title[0]?.arabicName;
      }
      if (l === "ku") {
        document.title = title[0]?.kurdishName;
      }
    }
  }, [l, title, titleISSuccess, titleLoading]);

  const languages = [
    {
      code: "ku",
      name: "Kurdish",
      dir: "rtl",
      country_code: "ku",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
    {
      code: "en-US",
      name: "English",
      dir: "ltr",
      country_code: "en-US",
    },
  ];

  const dispatch = useDispatch();
  const locale =
    Cookies.get("i18nextLng") !== null || Cookies.get("i18nextLng") !== undefined
      ? Cookies.get("i18nextLng")
      : "ar";

  // axios.defaults.baseURL = "https://localhost:7247/";
  // axios.defaults.baseURL = "https://4fbs1634-7247.euw.devtunnels.ms/";
  axios.defaults.baseURL = "https://varyhealthcare.com/api";
  axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

  return (
    <div
      className={`App min-w-full`}
      onCopy={(e) => {
        if (!window.location.href.includes("dashboard")) {
          e.preventDefault();
        }
      }}
    >
      {/* <Counter /> */}
      {/* <Index /> */}
      {/*  Web Routes */}

      {/* {showLodader && <Loader />} */}

      {/* {loading === true ? (
        <>
          <Loading />
        </>
      ) : (
        <> */}
      <ErrorBoundary>
        <ScrollToTop>
          <Routes basename={process.env.PUBLIC_URL}>
            <Route path="/" element={<Index />} exact />
            <Route path="/shop" element={<Shop />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/shop/:id" element={<FindShop />} />
            <Route path="shop/product/:id" element={<ProductDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/reset-password" element={<Reset />} />
            <Route path="/register" element={<Register />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/offers/:id" element={<FindOffers />} />
            <Route path="/profile" element={<Profiles />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/askvary" element={<AskVary />} />
            <Route path="/information" element={<Information />} />
            <Route path="/information/:id" element={<NewsDetail />} />
            <Route path="/terms" element={<Terms />} exact />
            {/* <Route path="/errorBoundary" element={<ErrorBoundary />} exact /> */}
            <Route element={<RequireAuth />}>
              <Route exact path="/checkout" element={<Checkout />} />
              <Route exact path="/userProfile" element={<Customer />} />
              <Route path="/cart" element={<Cart />} />
            </Route>

            {/* Dashboard Routes */}
            <Route
              path="/dashboard/login"
              element={<DashboardLogin />}
              exact
              name="dashboardLogin"
            />
            <Route element={<RequireAuthDashboard />}>
              <Route
                path="/dashboard"
                element={<Dashboard />}
                name="dashboardIndex"
              />
              <Route path="/dashboard/orders" element={<DashboardOrders />} />
              <Route path="/dashboard/chat" element={<DashboardChat />} />
              <Route path="/dashboard/items" element={<DashboardItems />} />
              <Route path="/dashboard/category" element={<DashboardCategory />} />
              <Route path="/dashboard/cities" element={<DashboardCities />} />
              <Route
                path="/dashboard/subcategory"
                element={<DashboardSubCategories />}
              />
              <Route path="/dashboard/offer" element={<DashboardOffers />} />
              <Route path="/dashboard/askvary" element={<DashboardAskVary />} />
              <Route path="/dashboard/users" element={<DashboardUsers />} />
              <Route path="/dashboard/profile" element={<DashboardProfiles />} />
              <Route path="/dashboard/brands" element={<DashboardBrands />} />
              <Route path="/dashboard/customers" element={<DashboardClients />} />
              <Route path="/dashboard/reports" element={<DashboardReports />} />
              <Route path="/dashboard/emails" element={<DashboardEmail />} />
              <Route
                path="/dashboard/reports-most-selling-categories"
                element={<DashboardReportMostSellingCategories />}
              />
              <Route path="/dashboard/banner" element={<DahboardBanner />} />
              <Route path="/dashboard/contactUs" element={<DahboardContact />} />
              <Route path="/dashboard/carousel" element={<DahboardCarousel />} />
              <Route
                path="/dashboard/notification"
                element={<DashboardNotification />}
              />
              <Route
                path="/dashboard/configuration"
                element={<DashboardConfig />}
              />
              <Route path="/dashboard/information" element={<DashboardNews />} />
            </Route>
            {/* <Route path="*" element={<ErrorPage />} /> */}
          </Routes>
        </ScrollToTop>
      </ErrorBoundary>

      {/* <div className="w-full h-screen">
  <Sugar color={'rgb(158, 38, 102)'} background="linear-gradient(180deg, #f95759 0%, #a62022 100%)" time={5000} animation="slide"   />
</div> */}
      {/* </>
      )} */}
    </div>
  );
}

export default App;
